.programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem 2rem;
}

.programs-header {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
}

.program-categories {
    display: flex;
    gap: 1rem;
}

.category {
    display: flex;
    flex-direction: column;
    background-color: gray;
    color: #fff;
    gap: 1rem;
    padding: 2rem;
    justify-content: space-between;
}

.category:hover {
    background: var(--planCard);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.category>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: #fff;
}

.category>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.category>:nth-child(3) {
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.join-now>img {
    width: 1rem;
}

@media screen and (max-width:912px) {
    .programs-header {
        font-size: 2.5rem;
    }

    .program-categories {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .category {
        width: 16.5rem;
    }
}

@media screen and (max-width:767px) {
    .programs-header {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .program-categories {
        flex-direction: column;
    }
}